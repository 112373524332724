<template>
  <!-- eslint-disable -->
  <div class="booking-body req-drive-main">
    <div class="req-drive-fold">
      <h2>Edit booking</h2>
      <form class="" method="post" @submit.prevent="onSubmit">
        <div class="req-drive-form">
          <div class="row">
            <div class="col-md-12">
              <div class="req-df-line">
                <v-date-picker
                  :model-config="{
                    type: 'string',
                    mask: 'YYYY-MM-DD',
                  }"
                  mode="date"
                  :masks="{ L: 'MM/DD/YYYY' }"
                  v-model="$v.form.service_date.$model"
                  :open-date="new Date()"
                  :min-date="minDate"
                  :update-on-input="false"
                >
                  <template #default="{ inputValue, togglePopover, inputEvents }">
                    <div class="form-group" @click="togglePopover()">
                      <label>When do you need this done? *</label>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="addon-wrapping" ref="service_date">
                            <img :src="require('@assets/images/calendar-form.svg')" alt="" />
                          </span>
                        </div>
                        <input
                          :readonly="isFormSubmit"
                          @keydown.enter.prevent=""
                          placeholder="mm/dd/yyyy"
                          class="form-control"
                          aria-describedby="addon-wrapping"
                          :value="inputValue"
                          v-on="inputEvents"
                          @focus="focusEvent('service_date')"
                          @blur="focusOut('service_date')"
                          @mouseover="isMinDateApply = true"
                        />
                      </div>
                      <span class="error" v-if="$v.form.service_date.$error">
                        Service date field is required
                      </span>
                    </div>
                  </template>
                </v-date-picker>
                <v-date-picker
                  v-model="$v.form.service_time.$model"
                  mode="time"
                  :model-config="{
                    type: 'string',
                    mask: 'hh:mm a',
                  }"
                >
                  <template #default="{ inputValue, togglePopover }">
                    <div class="form-group" @click="togglePopover()">
                      <label></label>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="addon-wrapping" ref="service_time">
                            <img :src="require('@assets/images/clock.svg')" alt="" />
                          </span>
                        </div>
                        <input
                          @keyup.delete="form.service_time = ''"
                          placeholder="HH:MM"
                          readonly
                          id="myTime"
                          class="form-control readOnly"
                          :value="inputValue"
                          @focus="focusEvent('service_time')"
                          @blur="focusOut('service_time')"
                        />
                      </div>
                      <span class="error" v-if="$v.form.service_time.$error">
                        Service time field is required
                      </span>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div class="req-df-line">
                <div class="form-group tbooking">
                  <label>Title of the booking *</label>
                  <input
                    :readonly="isFormSubmit"
                    type="text"
                    class="form-control"
                    placeholder="e.g. help with to deliver my package"
                    v-model.trim="$v.form.title_of_booking.$model"
                  />
                  <span class="error" v-if="$v.form.title_of_booking.$error">
                    Title of the booking field is required
                  </span>
                </div>
                <div class="form-group select">
                  <label>Choose a vehicle *</label>
                  <v-select
                    :disabled="isFormSubmit"
                    :readonly="isFormSubmit"
                    multiple
                    v-model="$v.form.choose_vehicles.$model"
                    :options="vehicle_option"
                    label="name"
                    :reduce="(e) => e.id"
                    placeholder="Select vehicle"
                    id="vehicle-choose"
                  />
                  <span class="error" v-if="$v.form.choose_vehicles.$error">
                    Choose a vehicle field is required
                  </span>
                </div>
                <div
                  class="form-group vehicle-num"
                  :class="$v.form.total_vehicle.$error ? 'error-show' : ''"
                >
                  <label>No. of vehicles *</label>
                  <input
                    :readonly="isFormSubmit"
                    type="number"
                    class="form-control"
                    v-model.trim="$v.form.total_vehicle.$model"
                  />
                  <span class="error" v-if="$v.form.total_vehicle.$error">
                    Total vehicles field is required
                  </span>
                </div>
              </div>
              <div class="req-df-line">
                <div class="form-group req-txtarea">
                  <label>Describe the type of work required. *</label>
                  <textarea
                    :readonly="isFormSubmit"
                    class="form-control"
                    placeholder="Write a summary of the key details"
                    v-model.trim="$v.form.description.$model"
                  ></textarea>
                  <span class="error" v-if="$v.form.description.$error">
                    Description field is required
                  </span>
                </div>
              </div>
              <div class="req-df-line">
                <div
                  class="form-group city"
                  :class="$v.form.pickup_address.$error ? 'start-city-show' : ''"
                >
                  <label>Starting city/Suburb *</label>
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="addon-wrapping" ref="start_city">
                        <img :src="require('@assets/images/location-1.svg')" alt="" />
                      </span>
                    </div>
                    <GmapAutocomplete
                      v-on:keydown.enter="preventFormSubmit('Pick')"
                      v-on:keydown.enter.prevent=""
                      class="form-control"
                      :value="form.pickup_address"
                      @focus="focusEvent('start_city')"
                      @blur="focusOut('start_city')"
                      placeholder="Sydney, New South Wales, Australia"
                      disabled
                    />
                    <!-- <input
                      :readonly="isFormSubmit"
                      type="text"
                      class="form-control"
                      placeholder="Sydney, New South Wales, Australia"
                      v-model.trim="$v.form.pickup_address.$model"
                    /> -->
                  </div>
                  <span class="error" v-if="$v.form.pickup_address.$error">
                    Starting city/Suburb field is required
                  </span>
                  <!-- <span class="error" v-if="$v.form.pickup_address.$error">
                    Enter Key is not allowed
                  </span> -->
                </div>
                <div
                  class="form-group city"
                  :class="$v.form.drop_address.$error ? 'end-city-show' : ''"
                  v-if="form.drop_longitude"
                >
                  <label>Ending city/Suburb</label>
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="addon-wrapping" ref="end_city">
                        <img :src="require('@assets/images/location-1.svg')" alt="" />
                      </span>
                    </div>
                    <GmapAutocomplete
                      v-on:keydown.enter="preventFormSubmit('Drop')"
                      v-on:keydown.enter.prevent=""
                      class="form-control"
                      :value="form.drop_address"
                      @focus="focusEvent('end_city')"
                      @blur="focusOut('end_city')"
                      placeholder="Sydney, New South Wales, Australia"
                      disabled
                    />
                    <!-- <input
                        :readonly="isFormSubmit"
                        type="text"
                        class="form-control"
                        placeholder="Sydney, New South Wales, Australia"
                        v-model="form.drop_address"
                      /> -->
                  </div>
                  <span class="error" v-if="$v.form.drop_address.$error">
                    Ending city/Suburb field is required
                  </span>
                </div>
              </div>

              <div class="req-df-line">
                <div class="form-group select">
                  <label>Recurring </label>
                  <select
                    :disabled="isFormSubmit"
                    class="form-select"
                    aria-label="Default select example"
                    v-model="repeat"
                  >
                    <option
                      v-for="repeat_op in repeat_option"
                      :key="repeat_op.value"
                      :value="repeat_op.value"
                    >
                      {{ repeat_op.title }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="booking-resource" v-if="repeat && repeat == '1'">
                <h5>Booking recurrence</h5>
                <div class="booking-wrap">
                  <div class="booking-left">
                    <label>Repeat every</label>
                    <div class="req-df-line">
                      <!-- <div class="form-group number">
                        <input type="number" class="form-control" />
                      </div> -->
                      <div class="form-group select">
                        <select
                          :disabled="isFormSubmit"
                          class="form-select"
                          aria-label="Default select example"
                          v-model.trim="$v.form.booking_recurrences_repeat_type.$model"
                        >
                          <option value="0">Daily</option>
                          <option value="1">Weekly</option>
                          <option value="2">Monthly</option>
                        </select>
                        <span class="error" v-if="$v.form.booking_recurrences_repeat_type.$error">
                          Repeat every field is required
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="booking-right">
                    <div class="book-week" v-show="form.booking_recurrences_repeat_type == '1'">
                      <label>Repeat on *</label>
                      <div class="day-wrapper">
                        <div class="check-day">
                          <input
                            :disabled="isFormSubmit"
                            type="checkbox"
                            id="myCheckbox1"
                            value="Sunday"
                            v-model.trim="$v.form.repeat_weekly.$model"
                          />
                          <label for="myCheckbox1">S</label>
                        </div>
                        <div class="check-day">
                          <input
                            :disabled="isFormSubmit"
                            type="checkbox"
                            id="myCheckbox2"
                            value="Monday"
                            v-model.trim="$v.form.repeat_weekly.$model"
                          />
                          <label for="myCheckbox2">M</label>
                        </div>
                        <div class="check-day">
                          <input
                            :disabled="isFormSubmit"
                            type="checkbox"
                            id="myCheckbox3"
                            value="Tuesday"
                            v-model.trim="$v.form.repeat_weekly.$model"
                          />
                          <label for="myCheckbox3">T</label>
                        </div>
                        <div class="check-day">
                          <input
                            :disabled="isFormSubmit"
                            type="checkbox"
                            id="myCheckbox4"
                            value="Wednesday"
                            v-model.trim="$v.form.repeat_weekly.$model"
                          />
                          <label for="myCheckbox4">W</label>
                        </div>
                        <div class="check-day">
                          <input
                            :disabled="isFormSubmit"
                            type="checkbox"
                            id="myCheckbox5"
                            value="Thursday"
                            v-model.trim="$v.form.repeat_weekly.$model"
                          />
                          <label for="myCheckbox5">TH</label>
                        </div>
                        <div class="check-day">
                          <input
                            :disabled="isFormSubmit"
                            type="checkbox"
                            id="myCheckbox6"
                            value="Friday"
                            v-model.trim="$v.form.repeat_weekly.$model"
                          />
                          <label for="myCheckbox6">F</label>
                        </div>
                        <div class="check-day">
                          <input
                            :disabled="isFormSubmit"
                            type="checkbox"
                            id="myCheckbox7"
                            value="Saturday"
                            v-model.trim="$v.form.repeat_weekly.$model"
                          />
                          <label for="myCheckbox7">Su</label>
                        </div>
                      </div>
                    </div>
                    <span class="error" v-if="$v.form.repeat_weekly.$error">
                      Day field is required
                    </span>
                    <div class="book-end">
                      <label>End</label>
                      <div class="end-option">
                        <div class="eo-radio">
                          <label class="er-btn"
                            >Never
                            <input
                              type="radio"
                              :disabled="isFormSubmit"
                              name="radio"
                              value="0"
                              v-model="repeat_every_end"
                            />
                            <span class="checkmark"></span>
                          </label>
                          <div class="action">
                            <label class="er-btn"
                              >On
                              <input
                                type="radio"
                                :disabled="isFormSubmit"
                                name="radio"
                                value="1"
                                v-model="repeat_every_end"
                              />
                              <span class="checkmark"></span>
                            </label>
                            <div class="other-info">
                              <div class="req-df-line">
                                <v-date-picker
                                  mode="date"
                                  :masks="{
                                    L: 'DD/MM/YYYY',
                                  }"
                                  v-model="$v.form.end_using_date.$model"
                                  :min-date="new Date(Date.now())"
                                  v-show="repeat_every_end === '1'"
                                  :model-config="{
                                    type: 'string',
                                    mask: 'YYYY-MM-DD',
                                  }"
                                  :update-on-input="false"
                                >
                                  <template #default="{ inputValue, togglePopover, inputEvents }">
                                    <div class="form-group date" @click="togglePopover()">
                                      <div class="input-group flex-nowrap">
                                        <div class="input-group-prepend">
                                          <span
                                            class="input-group-text"
                                            id="addon-wrapping"
                                            ref="end_on_datepicker"
                                          >
                                            <img
                                              :src="require('@assets/images/calendar-form.svg')"
                                              alt=""
                                            />
                                          </span>
                                        </div>
                                        <input
                                          @keydown.enter.prevent=""
                                          placeholder="dd/mm/yyyy"
                                          class="form-control"
                                          aria-describedby="addon-wrapping"
                                          :value="inputValue"
                                          v-on="inputEvents"
                                          @focus="focusEvent('end_on_datepicker')"
                                          @blur="focusOut('end_on_datepicker')"
                                        />
                                      </div>
                                      <span class="error" v-if="$v.form.end_using_date.$error">
                                        Date field is required
                                      </span>
                                    </div>
                                  </template>
                                </v-date-picker>
                              </div>
                            </div>
                          </div>
                          <div class="action">
                            <label class="er-btn"
                              >After
                              <input
                                type="radio"
                                :disabled="isFormSubmit"
                                name="radio"
                                value="2"
                                v-model="repeat_every_end"
                              />
                              <span class="checkmark"></span>
                            </label>
                            <div class="other-info">
                              <div class="req-df-line">
                                <div class="txt-wrapper" v-show="repeat_every_end === '2'">
                                  <div class="form-group">
                                    <input
                                      :readonly="isFormSubmit"
                                      type="number"
                                      class="form-control"
                                      v-model.trim="$v.form.end_total_repeat.$model"
                                    />
                                  </div>
                                  <span @click="form.end_total_repeat++">
                                    <i class="fas fa-chevron-up"></i>
                                  </span>
                                  <span
                                    @click="
                                      form.end_total_repeat > 0
                                        ? form.end_total_repeat--
                                        : (form.end_total_repeat = 0)
                                    "
                                    style="height: 39px !important"
                                  >
                                    <i class="fas fa-chevron-down"></i>
                                  </span>
                                  <label>occurrences *</label>
                                </div>
                                <span class="error" v-if="$v.form.end_total_repeat.$error">
                                  Occurrences field is required
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h5
                style="
                  font-size: 22px;
                  line-height: 27px;
                  font-family: 'Lato-bold';
                  margin-bottom: 12px;
                "
              >
                Pick-up contact details
              </h5>
              <div class="req-df-line">
                <div class="form-group tbooking">
                  <label>Name *</label>
                  <input
                    :readonly="isFormSubmit"
                    type="text"
                    class="form-control"
                    placeholder="e.g. Thomas"
                    v-model.trim="$v.form.pick_name.$model"
                    v-on:blur="sameAsPick"
                  />
                  <span class="error" v-if="$v.form.pick_name.$error">
                    Pick-up contact person name field is required
                  </span>
                </div>
                <div class="form-group tbooking">
                  <label>Number *</label>
                  <input
                    :readonly="isFormSubmit"
                    type="number"
                    class="form-control"
                    placeholder="e.g. 1300 222 321"
                    v-model.trim="$v.form.pick_number.$model"
                    v-on:blur="sameAsPick"
                  />
                  <span class="error" v-if="$v.form.pick_number.$error">
                    Pick-up contact person number field is required
                  </span>
                </div>
                <div class="form-group tbooking">
                  <label>Email *</label>
                  <input
                    :readonly="isFormSubmit"
                    type="email"
                    class="form-control"
                    placeholder="e.g. Thomas_Blue@gmail.com"
                    v-model.trim="$v.form.pick_email.$model"
                    v-on:blur="sameAsPick"
                  />
                  <span class="error" v-if="$v.form.pick_email.$error">
                    Pick-up contact person email field is required
                  </span>
                </div>
              </div>

              <h5
                style="
                  font-size: 22px;
                  line-height: 27px;
                  font-family: 'Lato-bold';
                  margin-bottom: 12px;
                "
              >
                Drop-off contact details
              </h5>
              <div>
                <input type="checkbox" @change="sameAsPick" v-model="sameAsPickIsChecked" />
                <label class="ms-2" style="font-family: 'Lato-bold'">Same as pick-up details</label>
              </div>
              <div class="req-df-line">
                <div class="form-group tbooking">
                  <label>Name</label>
                  <input
                    :readonly="isFormSubmit || sameAsPickIsChecked"
                    type="text"
                    class="form-control"
                    placeholder="e.g. Thomas"
                    v-model.trim="$v.form.drop_name.$model"
                  />
                  <span class="error" v-if="$v.form.drop_name.$error">
                    Drop-off contact person name field is required
                  </span>
                </div>
                <div class="form-group tbooking">
                  <label>Number</label>
                  <input
                    :readonly="isFormSubmit || sameAsPickIsChecked"
                    type="number"
                    class="form-control"
                    placeholder="e.g. 1300 222 321"
                    v-model.trim="$v.form.drop_number.$model"
                  />
                  <span class="error" v-if="$v.form.drop_number.$error">
                    Drop-off contact person number field is required
                  </span>
                </div>
                <div class="form-group tbooking">
                  <label>Email</label>
                  <input
                    :readonly="isFormSubmit || sameAsPickIsChecked"
                    type="email"
                    class="form-control"
                    placeholder="e.g. Thomas_Blue@gmail.com"
                    v-model.trim="$v.form.drop_email.$model"
                  />
                  <span class="error" v-if="$v.form.drop_email.$error">
                    Drop-off contact person email field is required
                  </span>
                </div>
              </div>

              <div class="form-group form-attachment">
                <button type="button" class="btn btn-secondary">
                  <span> <img :src="require('@assets/images/path1.svg')" alt="" /></span>Add
                  attachment
                  <input
                    type="file"
                    class="form-control"
                    multiple
                    @change="upload($event)"
                    style="width: 100%; position: absolute; left: 0; opacity: 0"
                  />
                </button>
                <label>You can upload image, pdf, excel, doc file formats</label>

                <!-- <input type="file" class="form-control" multiple @change="upload($event)" accept="image/jpeg,image/jpg,image/png,application/pdf"/> -->
              </div>

              <div
                class="attached-group"
                style="display: flex; flex-wrap: wrap; padding-bottom: 50px"
              >
                <div
                  v-for="(image, key) in images"
                  class="attached-image"
                  style="
                    position: relative;
                    display: block;
                    width: 100px;
                    height: 100px;
                    padding: 5px;
                    border: 1px solid #dfdfdf;
                    border-radius: 10px;
                    margin: 0 10px 10px;
                  "
                >
                  <a
                    @click="deleteAttachment(image.id, key)"
                    style="
                      width: 20px;
                      height: 20px;
                      position: absolute;
                      top: -9px;
                      right: -7px;
                      cursor: pointer;
                    "
                    v-if="image.id != 0"
                  >
                    <img
                      :src="require('@assets/images/cross-circle.png')"
                      alt=""
                      style="width: 100%; height: 100%; object-fit: contain"
                  /></a>
                  <a
                    @click="deleteMethod(key)"
                    style="
                      width: 20px;
                      height: 20px;
                      position: absolute;
                      top: -9px;
                      right: -7px;
                      cursor: pointer;
                    "
                    v-if="image.id == 0"
                  >
                    <img
                      :src="require('@assets/images/cross-circle.png')"
                      alt=""
                      style="width: 100%; height: 100%; object-fit: contain"
                  /></a>
                  <img
                    v-if="image && image.type == 'image'"
                    :src="image.url"
                    style="width: 100%; height: 100%; object-fit: contain"
                  />
                  <img
                    v-else-if="image && image.ext == 'pdf'"
                    :src="require('@assets/images/pdf-file.png')"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: contain"
                  />
                  <img
                    v-else-if="image && image.ext == 'xlsx' || image.ext == 'xls' || image.ext == 'csv'"
                    :src="require('@assets/images/excel.png')"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: contain"
                  />
                  <img
                    v-else-if="image && image.ext == 'docx' || image.ext == 'doc'"
                    :src="require('@assets/images/doc.png')"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: contain"
                  />
                  <img
                    v-else="image"
                    :src="require('@assets/images/file.png')"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: contain"
                  />
                </div>
              </div>

              <div
                class="budget-block"
                :class="$v.form.suggested_budget_amount.$error ? 'req-show' : ''"
              >
                <h5>Suggested budget (in AUD) *</h5>
                <div class="req-df-line">
                  <div class="form-group">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend">
                        <span
                          class="input-group-text"
                          id="addon-wrapping"
                          ref="suggested_budget_focus"
                          >$</span
                        >
                      </div>
                      <input
                        :readonly="isFormSubmit"
                        type="number"
                        class="form-control"
                        placeholder="100"
                        v-model.trim="$v.form.suggested_budget_amount.$model"
                        @focus="focusEvent('suggested_budget_focus')"
                        @blur="focusOut('suggested_budget_focus')"
                      />
                    </div>
                    <span class="error" v-if="$v.form.suggested_budget_amount.$error && !$v.form.suggested_budget_amount.required">
                      Suggested budget field is required
                    </span>
                    <span class="error" v-if="$v.form.suggested_budget_amount.$error && !$v.form.suggested_budget_amount.minValue">
                      Please select postive value
                    </span>
                  </div>
                  <div class="form-group select">
                    <select
                      :disabled="isFormSubmit"
                      class="form-select"
                      aria-label="Default select example"
                      v-model="suggested_budget_type"
                    >
                      <option
                        v-for="item in suggested_budget_option"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group"
                    v-if="suggested_budget_type && suggested_budget_type == '0'"
                  >
                    <input
                      :readonly="isFormSubmit"
                      type="number"
                      class="form-control"
                      placeholder="0"
                      v-model.trim="$v.form.suggested_budget_item_delivered.$model"
                    />
                    <span class="error" v-if="$v.form.suggested_budget_item_delivered.$error">
                      Number of items are being delivered field is required
                    </span>
                  </div>
                  <div
                    class="form-group"
                    v-if="suggested_budget_type && suggested_budget_type == '1'"
                  >
                    <input
                      :readonly="isFormSubmit"
                      type="number"
                      class="form-control"
                      placeholder="0"
                      v-model.trim="$v.form.suggested_budget_estimate_hours.$model"
                    />
                    <span class="error" v-if="$v.form.suggested_budget_estimate_hours.$error">
                      Suggested budget hour field is required
                    </span>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary" :disabled="isFormSubmit">
                  <span
                    v-if="isFormSubmit"
                    class="spinner-border spinner-border-lg"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span v-if="!isFormSubmit">Update this booking request</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';

export default {
  name: 'EditDriver',
  components: {},
  data() {
    return {
      value: '',
      vehicle_option: [],
      isShow: 0,
      isFormSubmit: false,
      address: '',
      startInput: '',
      dropInput: '',
      images: [],
      showInvalidFileError: false,
      form: {
        total_vehicle: '',
        choose_vehicles: [],
        service_date: '',
        service_time: '',
        title_of_booking: '',
        description: '',
        pickup_address: '',
        pickup_longitude: '',
        pickup_latitude: '',
        pick_name: '',
        pick_number: '',
        pick_email: '',
        drop_address: '',
        drop_longitude: '',
        drop_latitude: '',
        drop_name: '',
        drop_number: '',
        drop_email: '',
        pickup_place_id: '',
        drop_place_id: '',
        suggested_budget_amount: '',
        suggested_budget_estimate_hours: '',
        suggested_budget_item_delivered: '',
        attechments: [],
        booking_recurrences_repeat_type: '0',
        repeat_weekly: [],
        repeat_monthly: [],
        end_using_date: '',
        end_total_repeat: '',
      },
      tempForm: {
        drop_name: '',
        drop_number: '',
        drop_email: '',
      },
      sameAsPickIsChecked: false,
      currentBookingId: 0,
      repeat_every_end: '0',
      suggested_budget_type: '0',
      repeat: '0',
      isFetchingBooking: false,
      isMinDateApply: false,
      repeat_option: [
        {
          title: 'This does not recur',
          value: '0',
        },
        {
          title: 'Custom',
          value: '1',
        },
      ],
      suggested_budget_option: [
        {
          title: 'Per item delivered',
          value: '0',
        },
        {
          title: 'Per hour delivered',
          value: '1',
        },
      ],
    };
  },
  computed: {
    minDate() {
      return this.isMinDateApply ? new Date(Date.now()) : '';
    },
  },
  watch: {
    suggested_budget_type(val) {
      if (val === '0') {
        this.form.suggested_budget_estimate_hours = '';
      }
      if (val === '1') {
        this.form.suggested_budget_item_delivered = '';
      }
    },
    repeat(val) {
      if (val === '0') {
        this.form.booking_recurrences_repeat_type = '0';
        this.form.end_total_repeat = '';
        this.form.end_using_date = '';
        this.repeat_every_end = '0';
        this.form.repeat_monthly = [];
        this.form.repeat_weekly = [];
      }
    },
    repeat_every_end(val) {
      if (val === '0') {
        this.form.end_total_repeat = '';
        this.form.end_using_date = '';
      }
      if (val === '1') {
        this.form.end_total_repeat = '';
      }
      if (val === '2') {
        this.form.end_using_date = '';
      }
    },
    'form.booking_recurrences_repeat_type'(newVal) {
      if (newVal !== '1') {
        this.form.repeat_weekly = [];
      }
    },
  },
  created() {
    if (this.$route.params.booking_id) {
      this.currentBookingId = this.$route.params.booking_id;
      this.getspecificBooking(this.currentBookingId);
    }
    this.getVehicles();
  },
  validations: {
    form: {
      total_vehicle: {
        required,
      },
      title_of_booking: {
        required,
      },
      service_date: {
        required,
      },
      service_time: {
        required,
      },
      choose_vehicles: {
        required,
      },
      description: {
        required,
      },
      suggested_budget_amount: {
        required,
        minValue: minValue(0),
      },
      pickup_address: {
        required,
      },
      pick_name: {
        required,
      },
      pick_number: {
        required,
      },
      pick_email: {
        required,
      },
      drop_address: {
        // required,
      },
      drop_name: {
        // required,
      },
      drop_number: {
        // required,
      },
      drop_email: {
        // required,
      },
      booking_recurrences_repeat_type: {
        requiredIf: requiredIf(function () {
          return this.repeat === '1';
        }),
      },
      suggested_budget_estimate_hours: {
        requiredIf: requiredIf(function () {
          return this.suggested_budget_type === '1';
        }),
      },
      suggested_budget_item_delivered: {
        requiredIf: requiredIf(function () {
          return this.suggested_budget_type === '0';
        }),
      },
      end_total_repeat: {
        requiredIf: requiredIf(function () {
          return this.repeat_every_end === '2';
        }),
      },
      end_using_date: {
        requiredIf: requiredIf(function () {
          return this.repeat_every_end === '1';
        }),
      },
      repeat_weekly: {
        requiredIf: requiredIf(function () {
          return this.form.booking_recurrences_repeat_type === '1';
        }),
      },
    },
  },
  methods: {
    getspecificBooking(bookingId) {
      this.isFetchingBooking = true;
      this.$http
        .get(`/customer/booking/show/${bookingId}`)
        .then((res) => {
          const collection1 = res.data.data;
          const serviceDateTimeDBUTC = `${collection1.service_date} ${collection1.service_time}`;
          const serviceDateTimeDBLocal = moment
            .utc(serviceDateTimeDBUTC)
            .local()
            .format('YYYY-MM-DD HH:mm:ss');
          this.form = collection1;
          this.tempForm = Object.assign({}, collection1);
          this.form.choose_vehicles = res.data.data.choose_vehicles.split(',').map(Number);
          this.form.drop_name = ((res.data.data.drop_name) && res.data.data.drop_name !== 'null') ? res.data.data.drop_name : '';
          this.form.drop_email = ((res.data.data.drop_email) && res.data.data.drop_email !== 'null') ? res.data.data.drop_email : '';
          this.form.drop_name = res.data.data.drop_name;
          this.form.drop_email = res.data.data.drop_email;
          this.suggested_budget_type = collection1.suggested_budget_type;
          if(this.suggested_budget_type == 0){
            this.form.suggested_budget_amount = this.form.suggested_budget_amount / this.form.suggested_budget_item_delivered;
          }
          if(this.suggested_budget_type == 1){
            this.form.suggested_budget_amount  = this.form.suggested_budget_amount / this.form.suggested_budget_estimate_hours;
          }
          var attachments = res.data.data.attechments;
          for(let file of attachments)
          {
            var extArray = file.attechment_name.split('.');
            var ext = '';
            var type = 'application';
            if(extArray[2] !== undefined)
            {
              ext = extArray[2];
            }
            else{
              ext = extArray[1];
            }

            if(ext == 'jpeg' || ext == 'png' || ext == 'jpg')
            {
              type = 'image';
            }

            var obj = {
              type: type,
              url: file.image_url,
              ext: ext,
              id: file.id
            }
            this.images.push(obj);
          }
          this.form.service_time = moment(
            serviceDateTimeDBLocal.substring(serviceDateTimeDBLocal.indexOf(' ') + 1),
            ['HH.mm']
          ).format('hh:mm a');
          this.form.service_date = serviceDateTimeDBLocal.substring(
            0,
            serviceDateTimeDBLocal.indexOf(' ')
          );
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isFetchingBooking = false;
        });
    },
    preventFormSubmit(type) {
      this.$toast.error('Please select from suggested option');
      if (type == 'Pick') {
        setTimeout(this.clearPickPlace, 2000);
      }
      if (type == 'Drop') {
        setTimeout(this.clearDropPlace, 2000);
      }
    },
    /* eslint-disable */
    focusEvent(event) {
      if (event == 'service_date') {
        this.isMinDateApply = true;
        this.$refs.service_date.classList.add('border-show');
      }
      if (event == 'service_time') {
        this.$refs.service_time.classList.add('border-show');
      }
      if (event == 'start_city') {
        this.$refs.start_city.classList.add('border-show');
      }
      if (event == 'end_city') {
        this.$refs.end_city.classList.add('border-show');
      }
      if (event == 'suggested_budget_focus') {
        this.$refs.suggested_budget_focus.classList.add('border-show');
      }
      if (event == 'end_on_datepicker') {
        this.$refs.end_on_datepicker.classList.add('border-show');
      }
    },
    focusOut(event) {
      if (event == 'service_date') {
        this.isMinDateApply = false;
        this.$refs.service_date.classList.remove('border-show');
      }
      if (event == 'service_time') {
        this.$refs.service_time.classList.remove('border-show');
      }
      if (event == 'start_city') {
        this.$refs.start_city.classList.remove('border-show');
      }
      if (event == 'end_city') {
        this.$refs.end_city.classList.remove('border-show');
      }
      if (event == 'suggested_budget_focus') {
        this.$refs.suggested_budget_focus.classList.remove('border-show');
      }
      if (event == 'end_on_datepicker') {
        this.$refs.end_on_datepicker.classList.remove('border-show');
      }
    },
    setPickPlace(place) {
      let starting = place.formatted_address;
      this.startInput = starting;
      this.form.pickup_address = place;
      this.form.pickup_longitude = place.hasOwnProperty('geometry')
        ? place.geometry.location.lng()
        : null;
      this.form.pickup_latitude = place.hasOwnProperty('geometry')
        ? place.geometry.location.lat()
        : null;
      this.form.pickup_place_id = place.place_id;
    },
    setDropPlace(place) {
      let droping = place.formatted_address;
      this.dropInput = droping;
      this.form.drop_address = place;
      this.form.drop_longitude = place.hasOwnProperty('geometry')
        ? place.geometry.location.lng()
        : null;
      this.form.drop_latitude = place.hasOwnProperty('geometry')
        ? place.geometry.location.lat()
        : null;
      this.form.drop_place_id = place.place_id;
    },
    clearPickPlace() {
      (this.startInput = ''), (this.form.pickup_address = '');
      this.form.pickup_longitude = '';
      this.form.pickup_latitude = '';
      this.form.pickup_place_id = '';
    },
    clearDropPlace() {
      (this.dropInput = ''), (this.form.drop_address = '');
      this.form.drop_longitude = '';
      this.form.drop_latitude = '';
      this.form.drop_place_id = '';
    },
    /* eslint-enable */

    getVehicles() {
      this.$http
        .get('/getVehicalTypes')
        .then((res) => {
          this.vehicle_option = res.data.data;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {});
    },
    deleteMethod(key) {
      this.form.attechments.splice(key, 1);
      this.images.splice(key, 1);
    },
    /* eslint-disable */
    upload(event) {
      var selectedFiles = event.target.files;
      const accpetdType = event.target.accept.split(',');
      for (var i = 0; i < selectedFiles.length; i++) {
        var ext = selectedFiles[i]['name'].split('.')[1];
        // if (accpetdType.includes(selectedFiles[i].type)) {
          if (selectedFiles[i]['type'].split('/')[0] == 'image') {
            var obj = {
              type: 'image',
              url: URL.createObjectURL(selectedFiles[i]),
              ext: ext,
            };
            this.images.push(obj);
          }
          if (selectedFiles[i]['type'].split('/')[0] == 'application') {
            var obj = {
              type: 'application',
              url: URL.createObjectURL(selectedFiles[i]),
              ext: ext,
            };
            this.images.push(obj);
          }
        // } else {
        //   this.showInvalidFileError = true;
        // }
      }
      for (let file of event.target.files) {
        // if (accpetdType.includes(file.type)) {
          try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.form.attechments.push(file);
          } catch {}
        // }
      }
      if (this.showInvalidFileError) {
        this.showInvalidFileError = false;
        this.$toast.error('Please upload valid file type');
      }
    },
    sameAsPick() {
      console.log(this.tempForm);
      if (this.sameAsPickIsChecked) {
        this.form.drop_name = this.form.pick_name;
        this.form.drop_number = this.form.pick_number;
        this.form.drop_email = this.form.pick_email;
      } else {
        this.form.drop_name = this.tempForm.drop_name;
        this.form.drop_number = this.tempForm.drop_number;
        this.form.drop_email = this.tempForm.drop_email;
      }
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const isCurrentDate = moment().isSame(this.form.service_date, 'day');
        if (isCurrentDate) {
          const currentTimeWithSeleceTime =
            moment().format('HH:mm:ss') >
            moment(this.form.service_time, 'h:mm A').format('HH:mm:ss');
          if (currentTimeWithSeleceTime) {
            this.$toast.error('Please select time greater then current time');
            this.form.service_time = '';
            return false;
          }
        }
        this.isFormSubmit = true;
        const formdata = new FormData();
        const serviceDateTimeUTC = moment(
          `${this.form.service_date} ${this.form.service_time}`,
          'YYYY-MM-DD h:mm A'
        )
          .utc()
          .format('YYYY-MM-DD h:mm A');
        for (const key in this.form) {
          if (
            [
              'attechments',
              'repeat_weekly',
              'repeat_monthly',
              'choose_vehicles',
              // 'pickup_address',
              // 'drop_address',
              'service_date',
              'service_time',
            ].includes(key)
          ) {
            if ('attechments' == key) {
              this.form.attechments.forEach((e, i) => {
                if (e instanceof File) {
                  formdata.append(`attechments[${i}]`, this.form.attechments[i]);
                }
              });
            }
            // if ('pickup_address' == key) {
            //   formdata.append(
            //     `pickup_address`,
            //     `${this.form.pickup_address.name}, ${this.form.pickup_address.formatted_address}`
            //   );
            // }
            // if ('drop_address' == key) {
            //   formdata.append(
            //     `drop_address`,
            //     `${this.form.drop_address.name}, ${this.form.drop_address.formatted_address}`
            //   );
            // }
            if ('service_date' == key) {
              const serviceDateUTC = serviceDateTimeUTC.substring(
                0,
                serviceDateTimeUTC.indexOf(' ')
              );
              // formdata.append(`service_date`, moment(this.form.service_date).format('YYYY-MM-DD'));
              formdata.append(`service_date`, moment(serviceDateUTC).format('YYYY-MM-DD'));
            }
            if ('service_time' == key) {
              const serviceTimeUTC = serviceDateTimeUTC.substring(
                serviceDateTimeUTC.indexOf(' ') + 1
              );
              // formdata.append(`service_time`, moment(this.form.service_time, ["h:mm A"]).format("HH:mm:ss"));
              formdata.append(
                `service_time`,
                moment(serviceTimeUTC, ['h:mm A']).format('HH:mm:ss')
              );
              // formdata.append(`service_time`, moment(this.form.service_time).format('HH:mm:ss'));
            }
          } else {
            formdata.append(key, this.form[key]);
          }
        }
        this.form.choose_vehicles.forEach((e, i) => {
          formdata.append(`choose_vehicles[${i}]`, this.form.choose_vehicles[i]);
        });
        formdata.append('repeat', this.repeat);
        formdata.append('suggested_budget_type', this.suggested_budget_type);

        if (this.repeat == '1') {
          if (this.form.booking_recurrences_repeat_type == '1') {
            this.form.repeat_weekly.forEach((e, i) => {
              formdata.append(`repeat_weekly[${i}]`, this.form.repeat_weekly[i]);
            });
          }
          if (this.form.booking_recurrences_repeat_type == '2') {
            this.form.repeat_monthly.forEach((e, i) => {
              formdata.append(`repeat_monthly[${i}]`, this.form.repeat_monthly[i]);
            });
          }
          if (this.repeat_every_end == '1') {
            formdata.append(`end_using_date`, this.form.end_using_date);
          }
          if (this.repeat_every_end == '2') {
            formdata.append(`end_total_repeat`, this.form.end_total_repeat);
          }
        }
        formdata.append('_method', 'PATCH');
        this.$http
          .post(`/customer/booking/update/${this.currentBookingId}`, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            this.$toast.open('Request edit successfully', 'success');
            this.$router.push({ name: 'bookings' });
          })
          .catch((error) => {
            this.$toast.error('Some error occured');
            this.isFormSubmit = false;
          })
          .finally(() => {
            this.isFormSubmit = false;
          });
      }
    },
    deleteAttachment(id, key) {
      this.$http
        .post(`/customer/booking/remove-attachments`, {
          _method: 'delete',
          booking_id: this.currentBookingId,
          attechment_id: id,
        })
        .then((res) => {
          this.images.splice(key, 1);
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.vc-date {
  display: none;
}
.readOnly[readonly] {
  background-color: #ffffff !important;
}
</style>
